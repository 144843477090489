import { combineReducers } from "redux";
import userReducer from "./userReducer";
import websiteReducer from "./websiteReducer";
import appReducer from "./appReducer";
import paymentReducer from "./paymentReducer";
import emailReducer from "./emailReducer";
import promotionReducer from "./promotionReducer";
import notificationReducer from "./notificationReducer";
import shopReducer from "./shopReducer";
import productReducer from "./productReducer";
import productTemplateReducer from "./productTemplateReducer";
import packageReducer from "./packageReducer";
import orderReducer from "./orderReducer";
import teamReducer from "./teamReducer";
import statementReducer from "./statementReducer";
import designReducer from "./designReducer";
import productTikTokReducer from "./productTikTokReducer";
import printerReducer from "./printerReducer";
import trendingReducer from "./trendingReducer";
import categoryProductReducer from "./categoryProductReducer";
import returnOrdersReducer from "./returnOrdersReducer";
import productScheduleReducer from "./productScheduleReducer";
import amazonProductReducer from "./amazonProductReducer";

export default combineReducers({
    user: userReducer,
    website: websiteReducer,
    app: appReducer,
    payment: paymentReducer,
    email: emailReducer,
    promotion: promotionReducer,
    notification: notificationReducer,
    shop: shopReducer,
    product: productReducer,
    productTemplate: productTemplateReducer,
    package: packageReducer,
    order: orderReducer,
    team: teamReducer,
    statement: statementReducer,
    design: designReducer,
    producttiktok: productTikTokReducer,
    printer: printerReducer,
    trending: trendingReducer,
    categoryProduct: categoryProductReducer,
    returnOrders: returnOrdersReducer,
    productSchedule: productScheduleReducer,
    amazonTrending: amazonProductReducer,
});
