import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cx from "classnames";
import Loading from "components/Loading";
import Trending from "./trending";
import ProductSearch from "./productsearch";

function Main(props) {
    const [tab, setTab] = useState("trending");
    const { t } = useTranslation();
    var { isLoading } = props.amazonTrending;

    const onClick = (data) => {
        setTab(data);
    }

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "trending" })} to="/admin/spy/amazon" onClick={(e) => { onClick("trending") }}>{t("Trending")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "productsearch" })} to="/admin/spy/amazon" onClick={(e) => { onClick("productsearch") }}>{t("Product Search")}</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Loading isLoading={isLoading} />
            {
                tab === "trending" ?
                    <Trending />
                    :
                    <ProductSearch />
            }
        </Fragment>
    );
}

Main.propTypes = {
};
const mapStateToProps = state => ({
    amazonTrending: state.amazonTrending,
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);