import { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAmazonProducts, uploadTemplateByLink } from "actions/spytoolActions";
import { ReactTable } from "components/Table";
import { getProductTemplate } from "actions/productTemplateAction";
import {
    formatterCreateAt,
    sleep,
} from "components/Function";
import Loading from "components/Loading";
import _ from "lodash";
import Swal from "sweetalert2";
import ModalImport from "./modalImport";
import { CSVLink } from "react-csv";

const Trending = ({
    formatterCreateAt,
    productTemplate,
    getProductTemplate,
    uploadTemplateByLink,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [products_selected, setProductsSelected] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [keySearch, setKeySearch] = useState("");
    const btnRef = useRef(null);

    const { t } = useTranslation();

    const { list: listTemplate } = productTemplate;

    const list_template = listTemplate.map((v, i) => {
        return { "value": v._id, "label": v.title }
    })

    const onChange = (e) => {
        if (e.target.name === "keySearch") {
            setKeySearch(e.target.value);
        }
    }

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "All",
            value: totalSize || 50,
        },
    ];

    useEffect(() => {
        getProductTemplate()
    }, [getProductTemplate]);

    useEffect(() => {
        setTotalSize(totalSize);
    }, [totalSize]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "image",
            text: t("Image"),
            sort: true,
            formatter: (cell, row) => (
                <>
                    <div className="symbol symbol-50px">
                        <Link to={row.url || "#"} target="_blank"><img src={cell || require("components/assets/media/misc/image.png")} alt="" /></Link>
                    </div>
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            formatter: (cell, row) => (
                <>
                    <h3 >
                        {cell}
                    </h3>
                </>
            ),
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "updated_at",
            text: t("Update At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };



    const handleSubmitUpload = async (data) => {
        setIsLoading(true);
        let data_uniqe = _.uniqBy(data, "id");
        Promise.all(data_uniqe?.map(async (obj, iv) => {
            await sleep(1000 * iv)
            return new Promise(async (resolve2, reject2) => {
                await uploadTemplateByLink(obj).then((data_) => {
                    resolve2('ok');
                })
                    .catch((err) => {
                        resolve2('ok');
                    });
                resolve2('ok');
            });
        })).then(async (dat) => {
            setIsLoading(false)
            setProductsSelected([])
            return Swal.fire(t("Success"), t("Upload Success!"), "success");
        })
    };

    const onClickExportData = async () => {
        setIsLoading(true);
        let save_data_export = [];
        // save_data_export = list?.filter((v, i) => products_selected?.includes(v._id))?.map((v, i) => {
        //     return { title: v.title, image1: v?.image?.[0] || "", image2: v?.main_images?.[1] || "", image3: v?.main_images?.[2] || "", image4: v?.main_images?.[3] || "", image5: v?.main_images?.[4] || "", image6: v?.main_images?.[5] || "", image7: v?.main_images?.[6] || "", image8: v?.main_images?.[7] || "", image9: v?.main_images?.[8] || "" }
        // })
        setDataExport(save_data_export);
        setInitiateDownload(true);
        await sleep(2500)
        setIsLoading(false);
    }

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const handleSearchText = () => {

        return Swal.fire(t("Success"), t("Updating!"), "success");
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
        if (products_selected_tmp.length !== 0) {
            // setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            // setLoadingSync(true);
        }
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
        if (products_selected_tmp.length !== 0) {
            // setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            // setLoadingSync(true);
        }
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex flex-md-row flex-column gap-2">
                    <div className="form-group">
                        <label>Key Search</label>
                        <div>
                            <input type="text" className="form-control" value={keySearch} placeholder={t("")} name="keySearch" onChange={(e) => { onChange(e) }} />
                        </div>
                    </div>
                    <button
                        onClick={handleSearchText}
                        type="submit"
                        id=""
                        className="btn btn-sm btn-info"
                    >
                        {t("Search")}
                    </button>
                </div>
                <div className="mb-5 d-flex flex-md-row flex-column gap-2">
                    <button onClick={onClickExportData} className="btn btn-sm btn-success mr-1"><i className="ki-outline ki-exit-down"></i>Export</button>
                    <CSVLink filename={"data_amazon_" + dataExport.length + "_products.csv"} data={dataExport}>
                        <span ref={btnRef} />
                    </CSVLink>

                    <ModalImport
                        data={products_selected}
                        allData={[]}
                        template={list_template}
                        onSubmit={(data) => {
                            handleSubmitUpload(data)
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <div className="card min-w-full">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            columns={columns}
                            data={[]}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit al-d-none-option-react-select"
                            sizePerPageList={sizePerPageList}
                            isSelectRow={true}
                            selectRow={selectRow}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

const mapStateToProps = ({ productTemplate }) => ({ productTemplate });
const mapDispatchToProps = {
    formatterCreateAt,
    getAmazonProducts,
    getProductTemplate,
    uploadTemplateByLink
};

export default connect(mapStateToProps, mapDispatchToProps)(Trending);