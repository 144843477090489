import { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getSuggests } from "actions/spytoolActions";
import { formatterCreateAt, convertTZ, sleep } from "components/Function";
import { ReactTable } from "components/Table";
import SelectBox from "components/elements/selectBox";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ListDateType } from "helper/constant";
import { getProductTemplate } from "actions/productTemplateAction";
import { uploadTemplateByLink } from "actions/spytoolActions";
import _ from "lodash";
import ModalImport from "./modalImport";
import Swal from "sweetalert2";

function ListSuggests(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [filter, setFilter] = useState({});
    const [products_selected, setProductsSelected] = useState([]);
    const [dateType, setDateType] = useState("");
    const [isLoading_, setIsLoading_] = useState(false);
    const { t } = useTranslation();
    const [columns, setColumns] = useState([{
        dataField: '_id',
        text: "",
        sort: true,
        formatter: (cell, row, rowIndex) => rowIndex + 1
    }, {
        dataField: 'image',
        text: t("Image"),
        sort: true,
        formatter: (cell, row, rowIndex) => <><Link target="_blank" to={row.link}><img src={cell} className="img-thumbnail" alt="" width={200} /></Link></>
    }, {
        dataField: 'title',
        text: t("Title"),
        sort: true,
        formatter: (cell, row, rowIndex) => <><span className='h4'>{cell}</span></>
    }, {
        dataField: 'idEtsy',
        text: t("ID Etsy"),
        sort: true,
        headerClasses: 'd-none',
        classes: 'd-none',
    }, {
        dataField: 'views',
        text: t("Views"),
        sort: true,
    }, {
        dataField: 'favorers',
        text: t("Favorers"),
        sort: true,
    }, {
        dataField: 'shopSold',
        text: t("Shop Sold"),
        sort: true,
    }, {
        dataField: 'trendingBy',
        text: t("Trending By"),
        sort: true,
    }, {
        dataField: 'created_at',
        text: t("Created At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell),
    }, {
        dataField: 'updated_at',
        text: t("Updated At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell),
    }]);


    var { list, totalsize, isLoading } = props.trending;
    var { getSuggests, formatterCreateAt, getProductTemplate, uploadTemplateByLink } = props;

    const { list: listTemplate } = props.productTemplate;

    const list_template = listTemplate.map((v, i) => {
        return { "value": v._id, "label": v.title }
    })

    const list_trending = [
        { value: "TikTok", label: "TikTok" },
        { value: "Etsy", label: "Etsy" },
    ]

    useEffect(() => {
        getProductTemplate()
    }, [getProductTemplate]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(50);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(50);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    useEffect(() => {
        if (Object.keys(filter).length)
            getSuggests({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getSuggests, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const handleSubmitUpload = async (data) => {
        setIsLoading_(true);
        let data_uniqe = _.uniqBy(data, "id");
        Promise.all(data_uniqe?.map(async (obj, iv) => {
            await sleep(1000*iv)
            return new Promise(async (resolve2, reject2) => {
                await uploadTemplateByLink(obj).then((data_) => {
                    resolve2('ok');
                })
                    .catch((err) => {
                        resolve2('ok');
                    });
                resolve2('ok');
            });
        })).then(async (dat) => {
            setIsLoading_(false)
            setProductsSelected([])
            return Swal.fire(t("Success"), t("Upload Success!"), "success");
        })
    };

    useEffect(() => {
        var data_columns = [{
            dataField: '_id',
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1
        }, {
            dataField: 'image',
            text: t("Image"),
            sort: true,
            formatter: (cell, row, rowIndex) => <><Link to={row.link}><img src={cell} className="img-thumbnail" alt="" width={200} /></Link></>
        }, {
            dataField: 'title',
            text: t("Title"),
            sort: true,
            formatter: (cell, row, rowIndex) => <><span className='h4'>{cell}</span></>
        }, {
            dataField: 'idEtsy',
            text: t("ID Etsy"),
            sort: true,
            headerClasses: 'd-none',
            classes: 'd-none',
        }, {
            dataField: 'views',
            text: t("Views"),
            sort: true,
        }, {
            dataField: 'favorers',
            text: t("Favorers"),
            sort: true,
        }, {
            dataField: 'shopSold',
            text: t("Shop Sold"),
            sort: true,
        }, {
            dataField: 'trendingBy',
            text: t("Trending By"),
            sort: true,
        },{
            dataField: 'viewToday',
            text: t("View Today"),
            sort: true,
        }];
        if (list?.some((item) => item?.dailySold)) {
            data_columns.push({
                dataField: 'dailySold',
                text: t("Daily Today"),
                sort: true,
            })
        }
        data_columns.push({
            dataField: 'created_at',
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        }, {
            dataField: 'updated_at',
            text: t("Updated At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        })
        // console.log(data_columns)
        setColumns(data_columns)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);


    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2">
                <SelectBox
                    options={list_trending}
                    name="trendingBy"
                    value={filter.trendingBy || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            trendingBy: data.value || "",
                        }))
                    }

                    }
                />
                <div className="d-flex gap-4">
                    <Select
                        name="dateType"
                        options={ListDateType}
                        className="al-select"
                        classNamePrefix="select"
                        value={ListDateType.filter(({ value }) => value === dateType)}
                        onChange={(e) => {
                            setDateType(e?.value || "");
                        }}
                        placeholder="Select a date ... "
                    />
                    {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    ) : (
                        ""
                    )}
                    <ModalImport
                        data={products_selected}
                        allData={list}
                        template={list_template}
                        onSubmit={(data) => {
                            handleSubmitUpload(data)
                        }}
                    />
                </div>
            </div>
        );
    }

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    }

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    }

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll
    };

    const sizePerPageList = [
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '200', value: 200
        },
        {
            text: '500', value: 500
        },
        {
            text: '1000', value: 1000
        },
        {
            text: 'All', value: totalsize || 50
        }
    ];

    return (
        <Fragment>
            <div className="card min-w-full">
                <Loading isLoading={isLoading} />
                <Loading isLoading={isLoading_} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable columns={columns} data={list} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" isSelectRow={true} selectRow={selectRow} sizePerPageList={sizePerPageList} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

ListSuggests.propTypes = {
    formatterCreateAt: PropTypes.func.isRequired,
    getSuggests: PropTypes.func.isRequired,
    getProductTemplate: PropTypes.func.isRequired,
    uploadTemplateByLink: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    trending: state.trending,
    productTemplate: state.productTemplate,
});
const mapDispatchToProps = {
    formatterCreateAt,
    getSuggests,
    getProductTemplate,
    uploadTemplateByLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSuggests);