import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProductTemplate, deleteProductTemplate, shareProductTemplateById } from "actions/productTemplateAction";
import { getStaffs } from "actions/teamActions";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";
import { subStringText } from "components/Function";
import Swal from "sweetalert2";
import ModalShareTemplate from "./modalShareTemplate";

const ProductList = ({ getProductTemplate, deleteProductTemplate, product, getStaffs, subStringText, shareProductTemplateById }) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const { t } = useTranslation();
    const { list, totalsize, isLoading } = product;

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        getProductTemplate({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getProductTemplate, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);


    const onClickShareData = async (email, id) => {
        await shareProductTemplateById(email, id).then(async (data) => {
            if (data?.status === 200) {
                return Swal.fire(t("Success"), t("Shared Success!"), "success");
            } else {
                return Swal.fire(t("Error"), t("Share Failed!"), "error");
            }
        })

    }

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "main_images",
            text: t("Main Images"),
            sort: true,
            headerStyle: { width: "100px" },
            formatter: (cell, row) => (
                <>
                    <div className="symbol symbol-50px">
                        <img src={cell?.[0] || require("components/assets/media/misc/image.png")} alt="" />
                    </div>
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            headerStyle: { width: "650px" },
            formatter: (cell) => subStringText(cell, 80),
            sort: true,
        },
        {
            dataField: "skus",
            text: t("Skus"),
            sort: true,
            formatter: (cell, row) => <Fragment>{cell?.length > 0 ? "VARIANTS" : "PRODUCT"}</Fragment>,
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <>
                    <Link
                        role="button"
                        to={`/admin/product-template/edit/${row._id}`}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit")}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </Link>
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                        onClick={() =>
                            deleteProductTemplate(row._id).then(() =>
                                getProductTemplate({ page, sizeperpage: sizePerPage, search: searchText })
                            )
                        }
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                    <ModalShareTemplate
                        onSubmit={(data) => {
                            onClickShareData(data, row._id)
                        }}
                    />
                </>
            ),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const groupButton = () => {
        return <div className="mb-5">
            <div className="mb-5 d-flex flex-md-row flex-column justify-content-between w-100">
                <SelectBox
                    options={staffs}
                    name="filter_staff"
                    value={filter.staff || ""}
                    onChange={(data) =>
                        setFilter((prev) => ({
                            ...prev,
                            staff: data.value || "",
                        }))
                    }
                />
            </div>

        </div>;
    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ productTemplate }) => ({ product: productTemplate });
const mapDispatchToProps = {
    getProductTemplate,
    deleteProductTemplate,
    getStaffs,
    subStringText,
    shareProductTemplateById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
