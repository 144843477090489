import axios from "axios";
import { tokenConfig } from "./websiteActions";
import Swal from "sweetalert2";
import { t } from "i18next";

const getSuggests =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_TRENDING", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/suggests?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_TRENDING", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_TRENDING",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_TRENDING", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getProductEsty = (listId, link) => (dispatch, getState) => {
    return axios
        .post(`/api/spytool/get-esty`, { listId, link }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const getProductShopEsty = (link) => (dispatch, getState) => {
    return axios
        .post(`/api/spytool/get-shop-esty`, { link }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const uploadTemplateByLink = (data) => async (dispatch, getState) => {
    await axios
        .post("/api/product-template/upload-by-link", { data }, tokenConfig(getState))
        .then((res) => res.data.data)
        .catch((err) => {
            return;
        });
};

const getProductTikTokv1 = (type, link, shop) => (dispatch, getState) => {
    return axios
        .get(`/api/spytool/tiktokv1?type=${type}&link=${link}&shop=${shop}`, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const getAmazonProducts =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_AMAZON_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/amazon-product?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_AMAZON_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_AMAZON_PRODUCT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_AMAZON_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

export {
    getProductEsty,
    getProductShopEsty,
    uploadTemplateByLink,
    getProductTikTokv1,
    getSuggests,
    getAmazonProducts,
};
