import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getProductTemplate =
    ({ page = 1, sizeperpage = 1000, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PRODUCT_TEMPLATE", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/product-template?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PRODUCT_TEMPLATE", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_PRODUCT_TEMPLATE",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PRODUCT_TEMPLATE", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const addProductTemplate = (data) => async (dispatch, getState) => {
    const check = await Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add product template?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    });
    if (check?.value) {
        return axios
            .post("/api/product-template", data, tokenConfig(getState))
            .then((res) => {
                if (res.data.status === 200) {
                    Swal.fire(t("Success"), t(res.data.message), "success").then(
                        () => (window.location.href = "/admin/products")
                    );
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    }
};

const uploadProductTemplate = (data, id) => (dispatch, getState) => {
    const form = new FormData();
    for (var i = 0; i < data.length; i++) {
        form.append(`file[${i}]`, data[i]);
    }
    form.append("id", id);
    const options = {
        method: "POST",
        url: "/api/product-template/upload",
        headers: {
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
            "al-token": getState().user.token,
        },
        data: form,
    };
    return axios
        .request(options)
        .then((res) => {
            if (res.data.status === 200) {
                Swal.fire(t("Success"), "Upload success", "success");
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};
const uploadProductTemplateCSV = (data, id) => async (dispatch, getState) => {
    await axios
        .post("/api/product-template/uploadcsv", { data, id }, tokenConfig(getState)).then((res) => {
            return;
        })
        .catch((err) => {
            return;
        });
};

const uploadProductTemplateLink = (data, id) => async (dispatch, getState) => {
    await axios
        .post("/api/product-template/upload-link", { data, id }, tokenConfig(getState))
        .then((res) => res.data.data)
        .catch((err) => {
            return;
        });
};

const updateProductTemplate = (data) => (dispatch, getState) => {
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to update product template?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            axios
                .put("/api/product-template", data, tokenConfig(getState))
                .then((res) => {
                    if (res.data.status === 200) {
                        Swal.fire(t("Success"), t(res.data.message), "success");
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        }
    });
};

const deleteProductTemplate = (id) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete product template?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_PRODUCT_TEMPLATE", payload: true });
            return axios
                .delete(`/api/product-template/${id}`, tokenConfig(getState))
                .then((res) => {
                    if (res.data.status === 200) {
                        dispatch({ type: "LOADING_PRODUCT_TEMPLATE", payload: false });
                        Swal.fire(t("Success"), t(res.data.message), "success");
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({ type: "LOADING_PRODUCT_TEMPLATE", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        }
    });
};

const getProductTemplateById = (id) => (dispatch, getState) => {
    return axios
        .get(`/api/product-template/${id}`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const shareProductTemplateById = (email, id) => (dispatch, getState) => {
    return axios
        .post(`/api/product-template/share`, { id, email }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => {
            return;
        });
};

export {
    getProductTemplate,
    addProductTemplate,
    deleteProductTemplate,
    getProductTemplateById,
    updateProductTemplate,
    uploadProductTemplate,
    uploadProductTemplateCSV,
    uploadProductTemplateLink,
    shareProductTemplateById,
};
