import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    getProductTikToksFreeze,
} from "actions/productTikTokActions";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import {
    FormatterStatus,
} from "components/Function";

const ProductList = ({
    getProductTikToksFreeze,
    producttiktok,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const { t } = useTranslation();
    const { productTikToksFreeze, totalsizeFreeze, isLoading } = producttiktok;

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "All",
            value: totalsizeFreeze || 50,
        },
    ];

    useEffect(() => {
        getProductTikToksFreeze({ page, sizeperpage: sizePerPage, search: searchText });
    }, [getProductTikToksFreeze, page, sizePerPage, searchText]);

    useEffect(() => {
        setTotalSize(totalsizeFreeze);
    }, [totalsizeFreeze]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            headerStyle: { width: "50px" },
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "main_images",
            text: t("Main Images"),
            sort: true,
            headerStyle: { width: "100px" },
            formatter: (cell, row) => (
                <>
                    <img src={cell?.[0] || require("components/assets/media/misc/image.png")} className="img-thumbnail" width={250} alt="" />
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            headerStyle: { width: "650px" },
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(100);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const groupButton = () => {
        return (
            <>

            </>
        );
    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    <ReactTable
                        columns={columns}
                        data={productTikToksFreeze || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ producttiktok }) => ({ producttiktok });
const mapDispatchToProps = {
    getProductTikToksFreeze,
    FormatterStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
